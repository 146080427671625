import type { JSONContent } from "@tiptap/core";

// uses first paragraph of content as teaser
export function generateTeaser(content: JSONContent, length = 500): JSONContent | undefined {
  const para = content.content?.find(node => node.type === "paragraph")
  if (!para || !para.content) {
    console.warn('could not generate teaser from content', content)
    return undefined
  }
  let characterCount = 0
  const texts = para.content
    .filter(node => node.type === 'text')
    .filter(text => {
      characterCount += (text.text as string).length
      return characterCount < length
    })
  // remove any tailing colons and white spaces
  if (texts.length > 0) {
    texts[texts.length - 1].text = (texts[texts.length - 1].text as string).replace(/[.|\s]*$/, "")
  }

  return {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          ...texts,
          {
            type: 'text',
            text: '...'
          }
        ]
      }
    ]
  }
}
