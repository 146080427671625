<template>
  <template
    v-if="props.disabled || allowed"
  >
    <slot />
  </template>
</template>

<script setup lang="ts">
import type { UserRoleKey } from "~/utils/charon-types";

const props = defineProps<{
  roles?: UserRoleKey[]
  disabled?: boolean
}>()

const me = useMe()

const allowed = computed(() => me.value?.roles && (props.roles || []).some(r => me.value!!.roles!!.includes(r)))
</script>
